const NO_IMAGE = '/common/no-avatar.png';

const QUERY_KEYS = {
  PROJECTS: {
    GET_PROJECTS: 'GET_PROJECTS',
    GET_PROJECT_LISTING_INFO: 'GET_PROJECT_LISTING_INFO',
    GET_QUICK_BUY_LISTING_INFO: 'GET_QUICK_BUY_LISTING_INFO',
    GET_CARBON_MINTED_DASHBOARD: 'GET_CARBON_MINTED_DASHBOARD',
    GET_WOOD_BURNED_DASHBOARD: 'GET_WOOD_BURNED_DASHBOARD',
    GET_PROJECT_DOCUMENTS: 'GET_PROJECT_DOCUMENTS',
    GET_PROJECT_INFO_BY_MINT: 'GET_PROJECT_INFO_BY_MINT',
  },
  COMMON: {
    SEND_FEEDBACK: 'SEND_FEEDBACK',
    SEND_CONTACT: 'SEND_CONTACT',
    GET_TOP_PAGE: 'GET_TOP_PAGE',
  },
  USER: {
    GET_WALLET_INFO: 'GET_WALLET_INFO',
    GET_LIST_CARBON: 'GET_LIST_CARBON',
    GET_LIST_TX: {
      CARBON: 'GET_LIST_TX_CARBON',
      BURN: 'GET_LIST_TX_BURN',
    },
    GET_PROFILE_INFO: 'GET_PROFILE_INFO',
    GET_LIST_CERTIFICATE: 'GET_LIST_CERTIFICATE',
  },
};

export { NO_IMAGE, QUERY_KEYS };
